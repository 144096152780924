<template>
  <div>
    <transition name="fade">
      <campaign-full-page-form v-if="isOpenCreateForm" title="Создать площадку" @close="isOpenCreateForm = false">
        <create-platform-form
        @success="handleSuccessForm"
        @error="handleErrorForm"/>
      </campaign-full-page-form>
    </transition>

    <transition name="fade">
      <campaign-full-page-form v-if="editPlatformVisible" title="Редактировать площадку" @close="editPlatformData = null; editPlatformVisible = false">
        <edit-platform-form
            v-if="!!editPlatformVisible"
            :is-visible="!!editPlatformVisible"
            :data="{ platform: editPlatformData }"
            @success="handleSuccessEditForm"
            @error="handleErrorForm"
            />
      </campaign-full-page-form>
    </transition>

    <div v-if="!isOpenCreateForm && !editPlatformVisible">
    <div class="platforms__filters-wrapper">
      <div v-show="isLoadingCategories" class="platforms__loader">
        <b-spinner variant="primary" label="Spinning" class="platforms__spinner" />
      </div>
      <b-card no-body class="platforms__filters" :class="{'_loading' : isLoadingCategories}">

    <b-row class="my-2 px-2 d-flex justify-content-between">
      <b-col sm="4" class="mb-1 mb-md-0">
        <DropDownFilter :on-change="categoryChange"
          :facets="filterOptions"
          :state="queryObj.categoriesScope"
          track-by="id"  name="categoriesScope" placeholder="Выберите категорию"/>
      </b-col>
      <b-col sm="2" class="mb-1 mb-md-0">
        <b-form-select v-model="queryObj.is_active" :options="activeOptions" />
      </b-col>
      <b-col sm="4" class="mb-1 mb-md-0">
        <b-input-group>
          <b-form-input placeholder="Поиск..." v-model="searchRef" @keyup="startSearch" />
          <b-input-group-append is-text>
            <b-icon-search></b-icon-search>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col class="d-flex justify-content-end" sm="1">
        <b-button variant="primary" @click="isOpenCreateForm = true">Создать</b-button>
      </b-col>
    </b-row>
      </b-card>
    </div>

    <b-card no-body>
    <div ref="scroll">
    <table-lazy :is-loading="isLoading" :error="!!error" :is-loading-more="isLoadingMore">
      <platforms-table :sort-by="sortBy" :sort-desc="sortDesc" :platforms="data" @sort-change="handleSortChange" @edit="handleEditPlatform($event); handleEditPlatformVisible()" @toggle-active="handlePlatformToggleActive" />
    </table-lazy>
    </div>
  </b-card>
    </div>
  </div>
</template>

<script>
import CampaignFullPageForm from "@/components/CampaignFullPageForm"
import { BCard, BRow, BCol, BFormInput, BButton, BFormSelect, BSpinner, BInputGroup, BIconSearch } from 'bootstrap-vue'
import PlatformsTable from '@/components/PlatformsTable'
import PlatformForm from '@/components/PlatformForm'
import SidebarRight from '@/components/SidebarRight'

import TableLazy from '@/components/TableLazy'

import { withFormData } from '@/hoc/withFormData'
import { usePage } from '@/use/page'
import {
  fetchPlatforms,
  fetchCategories,
  createPlatform,
  activatePlatform,
  deactivatePlatform,
  editPlatform
} from '@/api/platforms'
import {ref} from "@vue/composition-api"
import {infiniteScroll} from "@/use/infiniteScroll"
import {queries} from "@/use/queries"
import {search} from "@/use/search"
import {activeToggle} from "@/use/actions/activeToggle"
import DropDownFilter from "@/components/DropDownFilter"
import {CREATE_PLATFORM_VALIDATION} from "@/constants/validation"
import {getToastificationErrorParams, getToastificationSuccessParams} from "@/helpers/toasts"
const CreatePlatformForm = withFormData(PlatformForm, createPlatform, CREATE_PLATFORM_VALIDATION)
const EditPlatformForm = withFormData(PlatformForm, editPlatform, CREATE_PLATFORM_VALIDATION)

export default {
  components: {
    DropDownFilter,
    PlatformsTable,
    CampaignFullPageForm,
    BCard,
    BInputGroup,
    BIconSearch,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormSelect,
    TableLazy,
    CreatePlatformForm,
    EditPlatformForm,
    SidebarRight
  },
  setup(_, ctx) {

    const activeOptions = [
      {
        value: null,
        text: 'Все площадки'
      },
      {
        value: 1,
        text: 'Активные'
      },
      {
        value: 0,
        text: 'Неактивные'
      }
    ]

    const filterOptions = ref([]);
    const isLoadingCategories = ref(true);
    const editPlatformData = ref(null);
    const {
      isLoading,
      data,
      error,
      fetchPageData,
      loadMoreData,
      isLoadingMore
    } = usePage(fetchPlatforms, _, ctx)
    const {scroll} = infiniteScroll(loadMoreData, isLoadingMore)
    const {queryObj} = queries(fetchPageData, ctx)
    const {
      searchRef,
      startSearch
    } = search(queryObj)
    const {handleToggleActive} = activeToggle()

    const sortBy = ref(null)
    const sortDesc = ref(null)


    const handleSortChange = (sort) => {
      sortBy.value = sort.sortBy;
      sortDesc.value = sort.sortDesc;
      queryObj.field = sort.sortBy;
      queryObj.order = sort.sortDesc ? 'desc' : 'asc';
    }

    const loadCategories = () => {
      fetchCategories()
          .then((res) => {
            filterOptions.value = res.data.data;
            isLoadingCategories.value = false;
          })
          .catch((err) => {
            console.log(err);
          })
    }

    const categoryChange = (value, name) => {
      queryObj[name] = value;
    }

    const handleEditPlatform = (id) => {
      editPlatformData.value = data.value.find((u) => u.id === id)
    }

    const handlePlatformToggleActive = (data) => handleToggleActive(data, activatePlatform, deactivatePlatform)

    loadCategories();


    return {
      isLoading,
      isLoadingCategories,
      data,
      error,
      fetchPageData,
      loadCategories,
      filterOptions,
      handleSortChange,
      sortBy,
      sortDesc,
      loadMoreData,
      isLoadingMore,
      scroll,
      queryObj,
      searchRef,
      editPlatformData,
      startSearch,
      activeOptions,
      categoryChange,
      handlePlatformToggleActive,
      handleEditPlatform
    }
  },
  data() {
    return {
      isOpenCreateForm: false,
      editPlatformVisible: false,
    }
  },
  methods: {
    handleEditPlatformVisible() {
      this.editPlatformVisible = true
    },
    handleSuccessForm() {
      this.isOpenCreateForm = false;
      this.$toast(getToastificationSuccessParams('Площадка успешно создана'))
      this.searchRef = '';
      this.fetchPageData()
    },

    handleErrorForm(e) {
      this.isOpenCreateForm.value = false;
      this.$toast(getToastificationErrorParams(e.message))
    },

    handleSuccessEditForm() {
      this.fetchPageData()
      this.editPlatformVisible = null;
      this.$toast(getToastificationSuccessParams('Площадка успешно отредактирована'))
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px;
}
</style>
<style lang="scss" scoped>
.platforms {

  &__filters-wrapper {
    position: relative;
  }

  &__loader {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 49%;
    width: 100%;
    height: 100%;
  }

  &__filters {

    &._loading {
      filter: blur(4px)
    }
  }
}
</style>
